import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import base64 from "base-64"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postJwtLogin } from "../../../helpers/fakebackend_helper";

function* loginUser({ payload: { user, history, to } }) {

  try {
    const { data } = yield call(postJwtLogin, "/user/login", {
      email: user.email,
      password: user.password,
      station: 1,
    });

    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);

    if (to == null || to == "") {
      history.replace("/dashboard");
    } else {
      to = base64.decode(to);

      history.replace(to);
    }
    yield put(loginSuccess(data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

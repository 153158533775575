import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import GaugeChart from "./GaugeChart";
import { useDispatch } from "react-redux";
import { Spinner } from "../../Components/Common/Spinner";

const Tanks = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {

    const fetchData = async () => {
      try {
        // dispatch(setApiError(''))
        setLoading(true);
  
        const { data } = await apiCall.get(`/inventory/tanks`);
  
        setData(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setApiError(error.message));
      }
    };

    fetchData();
  }, []);

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Tanques</title>
          </MetaTags>
          <BreadCrumb title="Tanques" link="/" pageTitle="Inicio" />
          {loading ? (
            <Spinner />
          ) : (
            <Row>
              {(data || []).map((item, key) => (
                <Col xl={4} lg={6}>
                  <Card key={key}>
                    <CardBody className="text-center">
                      <GaugeChart
                        title={`[${item.number}] ${item.name}`}
                        name={item.productName}
                        value={item.progress}
                        color={item.color}
                      />

                      <p
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          color: item.color ?? "#676868",
                        }}
                        className={"fs-4 fw-bold"}
                      >
                        {item.amount}/{item.capacity} Lts
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tanks;

import axiosInstace from './axiosInstance'

const getFile = async(url,fileName) => {

    try {

        var requestData = {
            url,
            method: 'GET',
            responseType: 'blob'
        }
        
        const {data } = await axiosInstace.request(requestData)

        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

    } catch (error) {
        if (error.response){
            if(error.response.data.errors){
               
                throw error;
            }
            else{
                throw new Error(error.message);
            }            
            
        }else if(error.request){
            throw new Error(error.message);
        }else if(error.message){
            throw new Error(error.message);
          
        }
    }
 
}

const apiCall={
    get :(url) => axiosInstace.get(url),
    getFile:(url,fileName) => getFile(url,fileName),
    post:(url,body) => axiosInstace.post(url,body),
    put : (url, body) => axiosInstace.put(url,body),
    delete: (url) => axiosInstace.delete(url)
};

export default apiCall;
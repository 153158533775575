import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Input, Label } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import { useDispatch } from "react-redux";
import { Spinner } from "../../Components/Common/Spinner";
import { setApiError } from "../../store/actions";
import { Link, withRouter } from "react-router-dom";
import ModalContainer from "react-modal-promise";
import { newRestoTableModal } from "./RestoTable/NewRestoTableModal";

const Resto = (props) => {
  const { history } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [vendedor, setVendedor] = useState("2");
  const [vendedorName, setVendedorName] = useState(null);

  const onChangeVendedor = (e) => {
    setVendedor(e.target.value);
  };

  const fetchData = async () => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      const { data } = await apiCall.get(`/resto/vendedor/${vendedor}`);

      setVendedorName(data);
    } catch (error) {
      setVendedorName(null);
      dispatch(setApiError(error.message));
    } finally {
      setLoading(false);
    }
  };

  const fetchRestoTables = async () => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      const { data } = await apiCall.get(`/resto/restotables/${vendedor}`);

      setData(data);
    } catch (error) {
      dispatch(setApiError(error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      await fetchData();
    }
  };

  useEffect(() => {
    (async () => {
      if (vendedorName === "" || vendedorName == null) {
        setData([]);
        return;
      }

      await fetchRestoTables();
    })();
  }, [vendedorName]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Resto</title>
          </MetaTags>
          <BreadCrumb title="Resto" link="/" pageTitle="Inicio" />
          <Row>
            <Col lg={6}>
              <div className="card">
                <div className="card-body">
                  <div className="live-preview">
                    <h5 className="fs-14 mb-3">Vendedor</h5>
                    <p
                      className={
                        vendedorName === "" ? "text-danger" : "text-muted"
                      }
                    >
                      {vendedorName === ""
                        ? "Vendedor inexistente"
                        : vendedorName}
                    </p>
                    <div className="input-group">
                      <Input
                        autoFocus
                        type="number"
                        className="form-control"
                        onKeyUp={onKeyUp}
                        value={vendedor}
                        onChange={onChangeVendedor}
                        aria-label="Vendedor"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        id="button-addon2"
                        onClick={async (e) => {
                          e.preventDefault();
                          await fetchData();
                        }}
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {loading ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {vendedorName != "" && vendedorName != null && (
                  <Col sm={3} lg={2} xs={6}>
                    <Card
                      onClick={() => {
                        newRestoTableModal()
                          .then(async (value) => {
                            history.push(
                              `/resto/table/new/${value}/${vendedor}`
                            );
                          })
                          .catch((e) => {});
                      }}
                    >
                      <CardBody className="text-center py-4">
                        <div className="avatar-sm mx-auto">
                          <div className="avatar-title bg-light rounded">
                          <i className={"bx bx-plus-circle fs-36 text-success"}></i>
                            {/* <img
                              src={imgAddButton}
                              alt=""
                              className="avatar-sm companyLogo-img"
                            /> */}
                          </div>
                        </div>
                        <h5 className="mt-4">NUEVA MESA</h5>
                        <p className="text-muted mb-0">----</p>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {(data || []).map((item, key) => (
                  <Col key={key} sm={3} lg={2} xs={6}>
                    <Link to={`/resto/table/${item.number}`}>
                      <Card>
                        <CardBody className="text-center py-4">
                          <div className="avatar-sm mx-auto">
                            <div className="avatar-title bg-light rounded">
                            <i className={"bx  bx-dish fs-36 text-primary"}></i>
                              {/* <img
                                src={imgTable}
                                alt=""
                                className="avatar-sm companyLogo-img"
                              /> */}
                            </div>
                          </div>
                          <h5 className="mt-4">MESA {item.number}</h5>
                          <p className="text-muted mb-0">
                            $ {item.total.toFixed(2)}
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </React.Fragment>
            )}
          </Row>
        </Container>
        <ModalContainer />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Resto);

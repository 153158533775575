import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import Shifts from "../pages/Shifts";
import News from "../pages/News";
import InChargeLogs from "../pages/Shifts/InCharge";

import AccountingCustomers from "../pages/Accounting/AccountingCustomers";
import AccountingSuppliers from "../pages/Accounting/AccountingSuppliers";
import SupplierPayments from "../pages/Accounting/SupplierPayments";
import Page500 from "../pages/Errors/Page500";
import Page404 from "../pages/Errors/Page404";

import Products from "../pages/Inventory/Products";
import ProductDetail from "../pages/Inventory/ProductDetail";
import Tanks from "../pages/Inventory/Tanks";
import StockControl from "../pages/Inventory/StockControl";

import Resto from "../pages/Resto";
import Pumps from "../pages/Pumps";
import RestoTable from "../pages/Resto/RestoTable";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/shifts", component: Shifts },
  { path: "/shifts/inchargelogs", component: InChargeLogs },
  { path: "/news", component: News },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/accounting/customers", component: AccountingCustomers },
  { path: "/accounting/suppliers", component: AccountingSuppliers },
  { path: "/accounting/supplierpayments/:id", component: SupplierPayments },
  
  { path: "/inventory/product/:id", component: ProductDetail },
  { path: "/inventory/products", component: Products },
  { path: "/inventory/tanks", component: Tanks },
  { path: "/inventory/stock", component: StockControl },

  { path: "/resto", component: Resto },
  { path: "/resto/table/:id", component: RestoTable },
  { path: "/resto/table/:id/:newId/:vendedor", component: RestoTable },

  { path: "/pumps", component: Pumps },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
    // Authentication Page
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/500", component: Page500 },
    { path: "/404", component: Page404 },
    // { path: "/forgot-password", component: ForgetPasswordPage },
    // { path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };
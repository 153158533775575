import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import { Link, useParams } from "react-router-dom";

import moment from "moment";
import { Spinner } from "../../Components/Common/Spinner";
import { useDispatch } from "react-redux";

function ProductDetail(props) {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch= useDispatch();
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        // dispatch(setApiError(''))
  
        setLoading(true);
  
        const { data } = await apiCall.get(`/inventory/product/${id}`);
  
        setData(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setApiError(error.message))
      }
    };
    
    fetchData();
  }, []);

  

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <MetaTags>
          <title>Grupo Oleum - Productos</title>
        </MetaTags>
        <BreadCrumb
          title={data?.name ?? ""}
          pageTitle="Productos"
          link="/inventory/products"
        />

        <Row>
          {loading ? (
            <Spinner />
          ) : (
            <Col lg={12}>
              {data && (
                <Card>
                  <CardBody>
                    <Row className="gx-lg-5">
                      <Col xl={12}>
                        <div className="mt-xl-0 mt-2">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h4>{data.name}</h4>
                              <div className="hstack gap-3 flex-wrap">
                                <div>
                                  <Link to="#" className="text-primary d-block">
                                    {data.category}
                                  </Link>
                                </div>
                                <div className="vr"></div>
                                <div className="text-muted">
                                  {data.departments}
                                </div>
                              </div>
                            </div>
                          </div>

                          <Row className="mt-4">
                            <Col lg={3} xs={6}>
                              <div className="p-2 border border-dashed rounded">
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm me-2">
                                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                                      <i className="ri-stack-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="text-muted mb-1">Depósito</p>
                                    <h5 className="mb-0">
                                      {data.stockDeposit}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} xs={6}>
                              <div className="p-2 border border-dashed rounded">
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm me-2">
                                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                                      <i className="ri-store-3-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="text-muted mb-1">Góndola</p>
                                    <h5 className="mb-0">{data.stockShelf}</h5>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="product-content mt-2">
                            <Nav tabs className="nav-tabs-custom nav-success">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1");
                                  }}
                                >
                                  Descripción
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "2",
                                  })}
                                  onClick={() => {
                                    toggleCustom("2");
                                  }}
                                >
                                  Precios
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "3",
                                  })}
                                  onClick={() => {
                                    toggleCustom("3");
                                  }}
                                >
                                  Historial
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                              activeTab={customActiveTab}
                              className="border border-top-0 p-4"
                              id="nav-tabContent"
                            >
                              <TabPane id="nav-speci" tabId="1">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th
                                          scope="row"
                                          style={{ width: "150px" }}
                                        >
                                          Precio de venta
                                        </th>
                                        <td>$ {data.salePrice}</td>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="row"
                                          style={{ width: "150px" }}
                                        >
                                          Precio de compra
                                        </th>
                                        <td>$ {data.purchasePrice}</td>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="row"
                                          style={{ width: "150px" }}
                                        >
                                          Impuestos internos
                                        </th>
                                        <td>$ {data.taxes}</td>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="row"
                                          style={{ width: "150px" }}
                                        >
                                          Otros impuestos
                                        </th>
                                        <td>$ {data.otherTaxes}</td>
                                      </tr>
                                      <tr>
                                        <th
                                          scope="row"
                                          style={{ width: "150px" }}
                                        >
                                          Margen útil
                                        </th>
                                        <td>{data.margin}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                              <TabPane id="nav-detail" tabId="2">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      {(data?.listPrices || []).map(
                                        (item, key) => (
                                          <tr key={key}>
                                            <th
                                              scope="row"
                                              style={{ width: "150px" }}
                                            >
                                              [{item.id}] {item.name}
                                            </th>
                                            <td>$ {item.price}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                              <TabPane id="nav-detail" tabId="3">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th scope="col">Fecha</th>
                                        <th>Desde</th>
                                        <th>Usuario</th>
                                        <th>Anterior</th>
                                        <th>Nuevo</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(data?.priceHistory || []).map(
                                        (item, key) => (
                                          <tr key={key}>
                                            <td>
                                              {moment(item.fromDate).format(
                                                "DD/MM/YYYY h:mm"
                                              )}
                                            </td>
                                            <td>{item.from}</td>
                                            <td>{item.userName}</td>
                                            <td>$ {item.lastSalePrice}</td>
                                            <td> ${item.newSalePrice}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ProductDetail;

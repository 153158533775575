import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Label,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import moment from "moment";
import { Spinner } from "../../Components/Common/Spinner";
import { useDispatch } from "react-redux";
import { setApiError } from "../../store/actions";
import ypf from "../../assets/images/brands/ypf.png";
import avatar1 from "../../assets/images/users/duilio.png";

const Dashboard = () => {
  const [openShifts, setOpenShifts] = useState(null);
  const [restoTables, setRestoTables] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOpenShifts = async () => {
      try {
        // dispatch(setApiError(''))

        setLoading(true);

        const { data } = await apiCall.get(`/shifts/openshifts`);

        setOpenShifts(data);
      } catch (error) {
        dispatch(setApiError(error.message));
      } finally {
        setLoading(false);
      }
    };

    const fetchRestoTables = async () => {
      try {
        // dispatch(setApiError(''))

        setLoading(true);

        const { data } = await apiCall.get(`/resto/restotables/0`);

        setRestoTables(data);
      } catch (error) {
        dispatch(setApiError(error.message));
      } finally {
        setLoading(false);
      }
    };

    fetchRestoTables();
    fetchOpenShifts();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Dashboard</title>
          </MetaTags>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div className="bg-soft-secondary">
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="col-md-auto">
                            <div className="avatar-md">
                              <div className="avatar-title bg-white rounded-circle">
                                <img src={ypf} alt="" className="avatar-xs" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <div>
                              <h4 className="fw-bold">EE SS Central</h4>
                              <div className="hstack gap-3 flex-wrap">
                                <div>
                                  <i className="ri-flag-line align-bottom me-1"></i>{" "}
                                  YPF
                                </div>
                                <div className="vr"></div>
                                <div>
                                  <i className="ri-building-line align-bottom me-1"></i>{" "}
                                  Mendoza 2329, Rosario
                                </div>
                                <div className="vr"></div>
                                <div className="badge rounded-pill bg-success fs-12">
                                  Online
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>

          {loading ? (
            <Spinner />
          ) : (
            <Row>
              <Col lg={4} md={6}>
                <Card className="">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                        <img
                          src={avatar1}
                          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile"
                        />
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <Input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                          />
                          <Label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="ri-camera-fill"></i>
                            </span>
                          </Label>
                        </div>
                      </div>
                      <h5 className="fs-16 mb-1">Duilio</h5>
                      <p className="text-muted mb-0">Administrador</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {(openShifts || []).length > 0 && (
                <Col lg={4} md={6}>
                  <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Turnos en curso
                      </h4>
                      <div className="d-flex gap-1">
                        {/* <button type="button" class="btn btn-soft-primary btn-sm">Ir a Turnos</button> */}
                      </div>
                    </CardHeader>
                    <CardBody className="p-0">
                      <ul className="list-group list-group-flush border-dashed mb-0">
                        {(openShifts || []).map((item, key) => (
                          <li
                            className="list-group-item d-flex align-items-center"
                            key={key}
                          >
                            <div className="flex-shrink-0">
                              <span
                                className={
                                  "avatar-title rounded fs-3 shadow bg-soft-primary"
                                }
                              >
                                <i className={"bx bx bx-user text-primary"}></i>
                              </span>
                              {/* <img src={item.img} className="avatar-xs" alt="" /> */}
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="fs-14 mb-1">
                                TURNO {item.shiftNumber}
                              </h6>
                              <p className="text-muted mb-0">
                                {item.departmentName}
                              </p>
                            </div>
                            <div className="flex-shrink-0 text-end">
                              {(item.sellers || []).map((item1, key1) => (
                                <h6 className="fs-14 mb-1">
                                  {item1.id} - {item1.name}
                                </h6>
                              ))}
                              <p className={"fs-12 mb-0 text-success"}>
                                {" "}
                                <i
                                  className={
                                    "fs-13 align-middle ri-calendar-line"
                                  }
                                ></i>{" "}
                                {moment(item.shiftDate).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {(restoTables || []).length > 0 && (
                <Col lg={4} md={6}>
                  <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Mesas abiertas
                      </h4>
                      <div className="d-flex gap-1">
                        {/* <button type="button" class="btn btn-soft-primary btn-sm">Ir a Turnos</button> */}
                      </div>
                    </CardHeader>
                    <CardBody className="p-0">
                      <ul className="list-group list-group-flush border-dashed mb-0">
                        {(restoTables || []).map((item, key) => (
                          <li
                            className="list-group-item d-flex align-items-center"
                            key={key}
                          >
                            <div className="flex-shrink-0">
                              <span
                                className={
                                  "avatar-title rounded fs-3 shadow bg-soft-primary"
                                }
                              >
                                <i className={"bx bx bx-dish text-primary"}></i>
                              </span>
                              {/* <img src={item.img} className="avatar-xs" alt="" /> */}
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="fs-14 mb-1">MESA {item.number}</h6>
                              <p className="text-muted mb-0">
                                $ {item.total.toFixed(2)}
                              </p>
                            </div>
                            <div className="flex-shrink-0 text-end">
                              <h6 className="fs-14 mb-1">MOZO</h6>
                              <p className={"fs-12 mb-0 text-primary"}>
                                {item.vendedor} - {item.vendedorName}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import { Spinner } from "../../Components/Common/Spinner";

const Pumps = (props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchData = async() => {
      try {
        const { data } = await apiCall.get(`/pumps/pumps`);

        setData(data);
        setError("");
      } catch (error) {
        setError("Se ha perdido la conexión con el controlador");
        //console.error(error);
      } finally {
        
      }
    }

    fetchData();

    const intervalId = setInterval(fetchData, 8000);

    // Para evitar fugas de memoria, limpiar el intervalo
    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Surtidores</title>
          </MetaTags>
          <BreadCrumb title="Surtidores" link="/" pageTitle="Inicio" />
          <Row>
            {loading ? (
              <Spinner />
            ) : (
              <React.Fragment>
                <Col xl={4}>
                  {error != "" && (
                    <Alert color="danger" className="alert-solid">
                      {error}
                    </Alert>
                  )}

                  <Card>
                    <CardBody>
                      <div className="table-responsive table-card">
                        <table className="table table-nowrap table-centered align-middle">
                          <tbody>
                            {(data || []).map((item, key) => (
                              <tr key={key}>
                                <td>
                                  <div className="avatar-sm flex-shrink-0">
                                    <span
                                      className={`avatar-title bg-${item.color} rounded-circle fs-20`}
                                    >
                                      <i className="bx bx-gas-pump"></i>
                                    </span>
                                  </div>
                                </td>
                                <td className="fw-medium">
                                  Surtidor {item.id}
                                </td>
                                <td className="">[5] INFINIA</td>
                                <td>
                                  <span
                                    className={`badge badge-soft-${item.color}`}
                                  >
                                    {item.statusStr}
                                  </span>
                                </td>
                                <td>
                                  {item.status == 3 && (
                                    <i className="bx bx-loader-alt bx-spin fs-20 align-middle text-secondary"></i>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pumps;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();

    const [isInventory, setIsInventory] = useState(false);
    const [isAccounting, setIsAccounting] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Inventory') {
            setIsInventory(false);
        }
        if (iscurrentState !== 'Accounting') {
            setIsAccounting(false);
        }
       
    }, [
        history,
        iscurrentState,
        isInventory,
        isAccounting,
    ]);

    const hideSidebar = (e) => {
        document.body.classList.remove("vertical-sidebar-enable");
    }

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            click: hideSidebar,
        },
        {
            id: "inventory",
            label: "Inventario",
            icon: "ri-store-3-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsInventory(!isInventory);
                setIscurrentState('Inventory');
                updateIconSidebar(e);
            },
            stateVariables: isInventory,
            subItems: [
                {
                    id: "productos",
                    label: "Productos",
                    link: "/inventory/products",
                    parentId: "inventory",
                    click: hideSidebar
                },
                {
                    id: "tanks",
                    label: "Tanques",
                    link: "/inventory/tanks",
                    parentId: "inventory",
                    click: hideSidebar,
                },
                {
                    id: "stock",
                    label: "Control de stock",
                    link: "/inventory/stock",
                    parentId: "inventory",
                    click: hideSidebar,
                },
                
            ],
        },
        {
            id: "accounting",
            label: "Cuentas corrientes",
            icon: "ri-git-repository-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAccounting(!isAccounting);
                setIscurrentState('Accounting');
                updateIconSidebar(e);
            },
            stateVariables: isAccounting,
            subItems: [
                {
                    id: "customers",
                    label: "Clientes",
                    link: "/accounting/customers",
                    parentId: "accounting",
                    click: hideSidebar,
                },
                {
                    id: "suppliers",
                    label: "Proveedores",
                    link: "/accounting/suppliers",
                    parentId: "accounting",
                    click: hideSidebar,
                },
            ],
        },
        {
            id: "shifts",
            label: "Turnos",
            icon: "ri-account-box-fill",
            link: "/shifts",
            click: hideSidebar,
        },
        {
            id: "restotable",
            label: "Resto",
            icon: "ri-restaurant-fill",
            link: "/resto",
            click: hideSidebar,
        },
        {
            id: "pumps",
            label: "Surtidores",
            icon: "ri-gas-station-fill",
            link: "/pumps",
            click: hideSidebar,
        },
        {
            id: "inchargelogs",
            label: "Logs",
            icon: "ri-archive-drawer-fill",
            link: "/shifts/inchargelogs",
            click: hideSidebar,
        },
        {
            id: "news",
            label: "Novedades",
            icon: "ri-newspaper-fill",
            link: "/news",
            click: hideSidebar,
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
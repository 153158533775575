import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

// Import Images
import error from "../../assets/images/error.svg";

const Page404 = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>404 | Grupo Oleum</title>
      </MetaTags>
      <div className="auth-page-wrapper">
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center pt-4">
                  <div className="">
                    <img
                      src={error}
                      alt=""
                      className="error-basic-img move-animation"
                    />
                  </div>
                  <div className="mt-n4">
                    <h1 className="display-1 fw-medium">404</h1>
                    <h3 className="text-uppercase">La página no existe 😭</h3>
                    <p className="text-muted mb-4">
                      La página que estás buscando no está disponible!
                    </p>
                    <Link to="/dashboard" className="btn btn-success">
                      <i className="mdi mdi-home me-1"></i>Volver al inicio
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Page404;

import { useState } from "react";
import { createModal } from "react-modal-promise";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Button,
  FormFeedback,
  Label,
} from "reactstrap";
import apiCall from "../../../api";

const EditRestoTableModal = ({
  product,
  productName,
  isOpen,
  onResolve,
  onReject,
}) => {
  const [quantity, setQuantity] = useState(product.quantity);
  const [obs, setObs] = useState(product.observaciones);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const reject = () => onReject();

  const increment = () => {
    setQuantity((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    if (quantity == 1) {
      return;
    }

    setQuantity((prevCount) => prevCount - 1);
  };

  const CheckRestoTable = async (id) => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      const { data } = await apiCall.get(`/resto/checkrestotable/${id}`);

      return data;
    } catch (error) {
      //dispatch(setApiError(error.message));
    } finally {
      setLoading(false);
    }
  };

  function isNumber(val) {
    return !isNaN(val);
  }

  const CreateRestoTable = async () => {
    if (isNumber(value)) {
      if (value > 0) {
        var result = await CheckRestoTable(value);

        if (result) {
          setInvalid(true);
        } else {
          onResolve(value);
        }
      }
    }
  };

  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      onResolve({quantity, obs});
    }
  };

  return (
    <Modal autoFocus={false} size="sm" isOpen={isOpen} toggle={reject}>
      <ModalHeader toggle={reject}>
        [{product.productId}] {productName}
      </ModalHeader>
      <ModalBody>
        <Row className="align-items-center g-3">
          <Col lg={12}>
            <h5 className="fs-13 fw-medium text-muted">Cantidad</h5>
            <div className="input-step full-width">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  decrement();
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity"
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                value={quantity}
                onKeyUp={onKeyUp}
                min="0"
                max="100"
                autoFocus={true}
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  increment();
                }}
              >
                +
              </button>
            </div>
            {product.esPlato && (
              <Input
              className="mt-3 form-control form-control-lg"
              type="text"
              value={obs}
              onChange={(e) => {
                setObs(e.target.value);
              }}
              id="validationCustom01"
              placeholder="Observaciones.."
              invalid={invalid}
            />
            )}
            
            {/* <FormFeedback type="invalid">
              Esta mesa ya está abierta
            </FormFeedback> */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={loading}
          onClick={async () => {
            onResolve({quantity, obs});
          }}
        >
          Cambiar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const editRestoTableModal = createModal(EditRestoTableModal);

import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";

import Select from "react-select";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../../api";
import { useDispatch } from "react-redux";
import { setApiError } from "../../../store/actions";
import { Spinner } from "../../../Components/Common/Spinner";
import ProductDetail from "./ProductDetail";
import ModalContainer from "react-modal-promise";

const Index = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [stockPlaces, setStockPlaces] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [selectedTypePlace, setSelectedTypePlace] = useState("Deposito");
  const [selectedStockPlace, setSelectedStockPlace] = useState(null);

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);


  function handleSelectTypePlace(selectedPlace) {
    setSelectedStockPlace(selectedPlace);
  }

  const onSetDate = (date) => {
    setData({ ...data, ["date"]: new Date(date) });
  };

  const clearData = async () => {
    setLoading(true);

    setEditMode(false);
    setProductDetails([]);

    await fetchData();

    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    try {
      // dispatch(setApiError(''))

      const { data } = await apiCall.get(`/inventory/stockinit`);

      data.date = new Date(data.date);

      setData(data);
    } catch (error) {
      // dispatch(setApiError(error.message))
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      // dispatch(setApiError(''))

      const { data } = await apiCall.get(`/inventory/products`);

      setProducts(data);
    } catch (error) {
      let errorMessage = `No se pudieron obtener los productos {error.message}`;

      dispatch(setApiError(errorMessage));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchProducts();

    setLoading(false);
  }, [fetchData, fetchProducts]);

  useEffect(() => {
    fetchPlaces();
  }, [selectedTypePlace]);


  const fetchPlaces = useCallback(async () => {
    try {
      // dispatch(setApiError(''))

      const { data } = await apiCall.get(
        `/inventory/stockPlaces/${selectedTypePlace}`
      );

      setStockPlaces(data);

      setSelectedStockPlace(data[0]);
    } catch (error) {
      // dispatch(setApiError(error.message))
    }
  }, []);

  const onChangePlaceType = (e) => {
    setSelectedTypePlace(e.target.value);
  };



  const postStock = async () => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      let stockSpreadSheet = data;

      let control = 1;

      if (selectedTypePlace === "Gondola") {
        control = 2;
      }

      stockSpreadSheet.control = control;
      stockSpreadSheet.number = selectedStockPlace.id;
      stockSpreadSheet.stockProducts = productDetails;

      var request = {
        stockSpreadSheet: stockSpreadSheet,
      };

      const response = await apiCall.post(`/inventory/stock`, request);

      if (response.data === true) {
        await clearData();
        setsuccess_dlg(true);
      }

      setLoading(false);
      // console.log(data);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.error(error.message);
      // dispatch(setApiError(error.message))
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Control de stock</title>
          </MetaTags>
          <BreadCrumb title="Control de stock" link="/" pageTitle="Inicio" />
          {loading ? (
            <Spinner />
          ) : (
            <Row className="justify-content-center">
              <Col md={12}>
                <Card>
                  {/* <Form className="needs-validation"> */}
                  <CardBody className="p-4">
                    <Row className="g-3">
                      <Col lg={3} xs={6}>
                        <Label for="invoicenoInput">Planilla No</Label>
                        <Input
                          type="text"
                          className="form-control bg-light border-0"
                          id="invoicenoInput"
                          disabled={true}
                          value={data?.id}
                        />
                      </Col>
                      <Col lg={3} xs={6}>
                        <div>
                          <Label for="date-field">Fecha</Label>
                          <Flatpickr
                            className="form-control"
                            value={data?.date}
                            onChange={onSetDate}
                            disabled={editMode}
                            options={{
                              allowInput: true,
                              altInput: true,
                              altFormat: "d/m/y",
                              dateFormat: "y-m-d",
                              mode: "single",
                              locale: Spanish,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className="border-bottom border-bottom-dashed p-4">
                    <Row>
                      <Col lg={4} xs={6}>
                        <div>
                          <div>
                            <Label for="companyAddress">Tipo</Label>
                          </div>
                          <div
                            className="mb-2 hstack gap-2 flex-wrap"
                            onChange={onChangePlaceType}
                          >
                            <Input
                              type="radio"
                              className="btn-check"
                              name="placeType"
                              id="success-outlined"
                              disabled={editMode}
                              value="Deposito"
                              defaultChecked
                            />
                            <Label
                              className="btn btn-outline-primary"
                              for="success-outlined"
                            >
                              Depósito
                            </Label>

                            <Input
                              type="radio"
                              className="btn-check"
                              name="placeType"
                              disabled={editMode}
                              value="Gondola"
                              id="danger-outlined"
                            />
                            <Label
                              className="btn btn-outline-primary shadow-none"
                              for="danger-outlined"
                            >
                              Góndola
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} xs={6}>
                        <div>
                          <div>
                            <Label for="companyAddress">Lugar</Label>
                          </div>
                          <div className="mb-2">
                            <Select
                              value={selectedStockPlace}
                              onChange={() => {
                                handleSelectTypePlace();
                              }}
                              options={stockPlaces}
                              isDisabled={editMode}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.id}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {editMode && (
                    <CardBody className="p-4">
                      <ProductDetail
                        products={products}
                        productDetails={productDetails}
                        setProductDetails={setProductDetails}
                        stockPlaceType={selectedTypePlace}
                        stockPlaceId={selectedStockPlace.id}
                      />
                    </CardBody>
                  )}

                  <CardBody className="p-2">
                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      {!editMode && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(true);
                          }}
                          className="btn btn-primary"
                        >
                          <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                          Iniciar inventario
                        </button>
                      )}

                      {editMode && (
                        <React.Fragment>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              setShowDialog(true);
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            onClick={async (e) => {
                              await postStock();
                            }}
                            className="btn btn-success"
                          >
                            <i className="ri-printer-line align-bottom me-1"></i>{" "}
                            Guardar
                          </button>
                        </React.Fragment>
                      )}
                    </div>
                  </CardBody>
                  {/* </Form> */}
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ModalContainer />
      {success_dlg && (
        <SweetAlert
          success
          title="¡Bien!"
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          El ajuste de stock se ha guardado exitosamente.
        </SweetAlert>
      )}
      {showDialog && (
        <SweetAlert
          title="¿Está seguro que desea cancelar este ajuste de stock?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async() => {
            setShowDialog(false);
            await clearData();
          } }
          onCancel={() =>
            {
             setShowDialog(false);
            }
        }
        >
          ¡Perderá todo lo cargado hasta ahora!
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default Index;

import { useEffect, useMemo, useState } from "react";
import { createModal } from "react-modal-promise";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import { Link } from "react-router-dom";

const ProductsModal = ({ products, isOpen, onResolve, onReject }) => {
  const [value, setValue] = useState(null);
  const reject = () => onReject();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: "Nombre",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              onClick={(e) => {
                setValue(cell.row.original.id)
              }}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      onResolve(value);
    }
  }, [value]);

  return (
    <Modal autoFocus={false} size="lg" isOpen={isOpen} toggle={reject}>
      <ModalHeader toggle={reject}>Productos</ModalHeader>
      <ModalBody>
        <TableContainer
          columns={columns}
          data={products}
          customPageSize={10}
          isGlobalFilter={true}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap"
          theadClass="table-light text-muted"
        />
      </ModalBody>
    </Modal>
  );
};

export const productsModal = createModal(ProductsModal);

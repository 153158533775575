import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Table, Button, Row, Col } from "reactstrap";
import apiCall from "../../../api";
import { productsModal } from "./ProductsModal";
import BarcodeReader from "react-barcode-reader";

const ProductDetail = (props) => {
  const {
    products,
    productDetails,
    setProductDetails,
    stockPlaceType,
    stockPlaceId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [productValue, setProductValue] = useState(null);

  const inputRefs = useRef([]);
  const newInputRef = useRef(null);

  const updateStkR = (index, value) => {
    let val = parseInt(value);

    let tempItems = [...productDetails];

    let tempItem = {
      ...tempItems[index],
      ["stkR"]: val,
    };

    if (!isNaN(val)) {
      tempItem["diff"] = val - tempItem["stkC"];
    } else {
      tempItem["diff"] = 0;
    }

    tempItems[index] = tempItem;

    setProductDetails(tempItems);
  };


  
  const fetchProduct = async (id, barcode = null) => {
    try {
      // dispatch(setApiError(''))

      setProductValue("");

      setError("");

      setLoading(true);

      let tempStockPlaceType = 1;

      if (stockPlaceType === "Gondola") {
        tempStockPlaceType = 2;
      }

      let url = "";

      if (barcode === null) {
        url = `/inventory/stockProduct?id=${parseInt(
          id
        )}&stockPlaceType=${tempStockPlaceType}&stockPlaceId=${stockPlaceId}`;
      } else {
        url = `/inventory/stockProduct?barcode=${barcode}&stockPlaceType=${tempStockPlaceType}&stockPlaceId=${stockPlaceId}`;
      }

      const { data } = await apiCall.get(url);

      setLoading(false);

      if (productDetails.some((p) => p.id === data.id)) {
        setError("Ya existe el elemento en la lista");
        return;
      }

      setProductDetails([...productDetails, data]);

      
    } catch (error) {
      setError(error.message);
      setLoading(false);
      // dispatch(setApiError(error.message))
    }
  };

  const removeItem = (key) => {
    let arr = productDetails.filter((o, index) => index !== key);

    setProductDetails(arr);
  };

  const handleScan = async (data) => {
    await fetchProduct(null, data);
  };

  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      let str = e.target.value;

      if (str?.length > 10) {
        await fetchProduct(null, e.target.value);
      } else {
        await fetchProduct(e.target.value);
      }
    }
  };

  const onChangeProductValue = (e) => {
    setProductValue(e.target.value);
  };
  const handleClick = async () =>
    productsModal({
      products: products,
    })
      .then(async (value) => {
        await fetchProduct(value);
      })
      .catch((e) => {});

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col lg={6}>
          <div className="input-group">
            <Button
              onClick={handleClick}
              color="secondary"
              className="btn-icon"
              disabled={loading}
            >
              {loading ? (
                <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
              ) : (
                <i className="ri-search-2-line" />
              )}
            </Button>
            <Input
              type="text"
              className="form-control"
              onKeyUp={onKeyUp}
              value={productValue}
              onChange={onChangeProductValue}
              disabled={loading}
              placeholder="Ingrese un producto"
            />
          </div>
        </Col>
        {error != "" && <div className="text-danger">{error}</div>}
      </Row>
      <div className="table-responsive">
        <table className="table table-borderless align-middle mb-0">
          <thead className="table-light text-muted">
            <tr>
              <th scope="col">Productos</th>
              <th
                style={{ width: "100px" }}
                scope="col"
                className="text-end"
              ></th>
              <th style={{ width: "40px" }} scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {(productDetails || []).map((item, key) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td>
                    <h5 className="fs-14">
                      [{item.id}] {item.name}
                    </h5>

                    <p className="text-muted mb-0">Computado: {item.stkC}</p>
                  </td>
                  <td className="text-end">
                    Dif: {item.diff}
                    <Input
                      type="number"
                      className="form-control-sm"
                      value={item.stkR}
                      ref={(el) => {
                        inputRefs.current[key] = el;
                        if (key === productDetails.length - 1) {
                          newInputRef.current = el;
                        }
                      }}
                      placeholder="0.00"
                      onChange={(e) => {
                        updateStkR(key, e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        removeItem(key);
                      }}
                      className="btn btn-icon btn-danger"
                      disabled={loading}
                    >
                      <i className="ri-delete-bin-line" />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <BarcodeReader onScan={handleScan} />
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;

import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import { Spinner } from "../../Components/Common/Spinner";
import { useDispatch } from "react-redux";
import BarcodeReader from 'react-barcode-reader';

const Products = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch= useDispatch();

  const handleScan = (data) => {
    console.log(data);
  }


  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: "Nombre",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/inventory/product/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Departamentos",
        accessor: "departments",
        filterable: false,
        disableGlobalFilter: true,
      },
      {
        Header: "Precio",
        accessor: "salePrice",
        filterable: false,
        disableGlobalFilter: true,
        Cell: (cell) => {
          return <span> $ {cell.value}</span>;
        },
      },
      {
        Header: "Barcode",
        accessor: "barcode",
        filterable: false,
      },
    ],
    []
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        // dispatch(setApiError(''))
        setLoading(true);
  
        const { data } = await apiCall.get(`/inventory/products`);
  
        setLoading(false);
  
        setData(data);
      } catch (error) {
        setLoading(false);
        // dispatch(setApiError(error.message))
      }
    };

    fetchData();
  }, []);

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Productos</title>
          </MetaTags>
          <BreadCrumb title="Productos" link="/" pageTitle="Inicio" />
          <Row>
            {loading ? (
              <Spinner />
            ) : (
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0 flex-grow-1">Productos</h4>
                  </CardHeader>

                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={data}
                      customPageSize={10}
                      isGlobalFilter={true}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <div>
        <BarcodeReader
          onScan={handleScan}
          />
      </div>
    </React.Fragment>
  );
};

export default Products;

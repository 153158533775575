import React from "react";
import ReactEcharts from "echarts-for-react";

const GaugeChart = (props) => {

    const {title,name,value, color} = props;

    var option = {
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
        },
        color: [`${color ?? '#676868'}`],
        title:{
            show: true,
            text: title,
            textStyle: {
                color: `${color ?? '#858d98'}`,
                fontSize: 16,
                
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif',
        },
        series: [{
            name: 'Nivel',
            type: 'gauge',
            progress: {
                show: true
            },
            detail: {
                valueAnimation: true,
                formatter: '{value}',
                color: '#858d98',
            },
            axisLabel: {
                color: '#858d98',
            },
            data: [{
                title: {
                    color: '#858d98',
                    fontSize: 14
                },
                value: value,
                name: name,
            }]
        }]
    };
    return (
        <React.Fragment>
            <ReactEcharts style={{ height: '350px' }} option={option} />
        </React.Fragment>
    )
}

export default GaugeChart;
import { useState } from "react";
import { createModal } from "react-modal-promise";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import apiCall from "../../../api";

const NewRestoTableModal = ({ isOpen, onResolve, onReject }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const reject = () => onReject();

  const CheckRestoTable = async (id) => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      const { data } = await apiCall.get(`/resto/checkrestotable/${id}`);

      return data;
    } catch (error) {
      //dispatch(setApiError(error.message));
    } finally {
      setLoading(false);
    }
  };
  function isNumber(val) {
    return !isNaN(val);
  }

  const CreateRestoTable = async () => {
    if (isNumber(value)) {
      if (value > 0) {
        var result = await CheckRestoTable(value);

        if(result){
          setInvalid(true);
        }else{
          onResolve(value);
        }
          
      }
    }
  };

  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      await CreateRestoTable();
    }
  };

  return (
    <Modal autoFocus={false} size="sm" isOpen={isOpen} toggle={reject}>
      <ModalHeader toggle={reject}>Nueva mesa</ModalHeader>
      <ModalBody>
        <Row className="align-items-center g-3">
          <Col lg={12}>
            <Input
              autoFocus={true}
              className="form-control form-control-lg"
              type="number"
              onKeyUp={onKeyUp}
              id="validationCustom01"
              placeholder="Ingrese el número de mesa"
              value={value}
              invalid={invalid}
              onChange={(e) => {
                if(invalid){
                  setInvalid(false);
                }
                setValue(e.target.value);
              }}
            />
            <FormFeedback type="invalid">Esta mesa ya está abierta</FormFeedback>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={loading}
          onClick={async () => {
            await CreateRestoTable();
          }}
        >
          Crear
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const newRestoTableModal = createModal(NewRestoTableModal);

import React from "react";
import { ThreeDots } from "react-loader-spinner";
import "./spinner.css";

export const Spinner = (props) => {
  return (
    <div className="loader">
      <ThreeDots color="#3577f1" height="100" width="100" />
    </div>
  );
};

import base64 from "base-64"

const useLogin = () => {

  const destroyToken = () => {

    localStorage.removeItem("user");
    localStorage.removeItem("menu");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

  }

  const ToLogin = () => {

    let url = window.location.pathname + window.location.search

    if(url != '' && url != "/login"){
      url = base64.encode(url)
      window.location = "/login?to=" + url
    }else{
      window.location = "/login"
    }

  }

  const ToLoginPath = () => {

    let url = window.location.pathname + window.location.search

    if(url != '' && url != "/login"){
      url = base64.encode(url)
      
      return "/login?to=" + url
    }else{
      return "/login"
    }

  }
  return {
    destroyToken,
    ToLogin,
    ToLoginPath
  }
}

export default useLogin
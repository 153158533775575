import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../../api";
import { useDispatch } from "react-redux";
import { Spinner } from "../../../Components/Common/Spinner";
import { setApiError } from "../../../store/actions";
import { withRouter, Link, useParams } from "react-router-dom";
import { productsModal } from "./ProductsModal";
import { editRestoTableModal } from "./EditRestoTableModal";
import ModalContainer from "react-modal-promise";
import SweetAlert from "react-bootstrap-sweetalert";

const RestoTable = (props) => {
  const { history } = props;
  const { id, newId, vendedor } = useParams();
  const [restoTable, setRestoTable] = useState(null);
  const [productValue, setProductValue] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const inputProductRef = useRef();

  const dispatch = useDispatch();

  const increment = () => {
    setQuantity((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    if (quantity == 1) {
      return;
    }

    setQuantity((prevCount) => prevCount - 1);
  };
  const fetchData = async () => {
    try {
      // dispatch(setApiError(''))

      const { data } = await apiCall.get(`/resto/restotable/${id}`);

      setRestoTable(data);
    } catch (error) {
      dispatch(setApiError(error.message));
    } finally {
      //setLoading(false);
    }
  };

  const fetchProducts = useCallback(async () => {
    try {
      // dispatch(setApiError(''))

      const { data } = await apiCall.get(`/resto/products`);

      setProducts(data);
    } catch (error) {
      let errorMessage = `No se pudieron obtener los productos {error.message}`;

      dispatch(setApiError(errorMessage));
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await fetchProducts();

      if (id == "new") {
        setRestoTable({
          number: newId,
          vendedor: vendedor,
          items: [],
          total: 0,
        });

        setLoading(false);

        return;
      }
      await fetchData();

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let tempTotal = 0;

    restoTable?.items?.forEach((i) => {
      let subTotal = 0;
      subTotal = i.quantity * (i.price * (1 + i.tasaIva1));
      tempTotal = tempTotal + subTotal;
    });

    setTotal(tempTotal);
  }, [restoTable?.items]);

  const ProductName = (id) => {
    let product = products.find((p) => p.id == id);
    return product.name;
  };

  const ProductTotal = (product) => {
    return product.quantity * (product.price * (1 + product.tasaIva1));
  };
  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      await fetchProduct(productValue);
    }
  };

  const onChangeProductValue = (e) => {
    setProductValue(e.target.value);
  };

  const removeItem = (key) => {
    let tempItems = [...restoTable.items];
    let arr = tempItems.filter((o, index) => index !== key);

    setRestoTable({ ...restoTable, items: arr });
  };
  const fetchProduct = async (id) => {
    if (id == "") {
      return;
    }

    try {
      dispatch(setApiError(""));

      // setProductValue("");

      // setError("");

      setProductLoading(true);

      let url = `/resto/product/${parseInt(id)}`;

      const { data } = await apiCall.get(url);

      data.quantity = quantity;

      let tempItems = [...restoTable.items];

      tempItems.push(data);

      setRestoTable({ ...restoTable, items: tempItems });

      inputProductRef.current.focus();

      setQuantity(1);
      setProductValue("");

      // if (productDetails.some((p) => p.id === data.id)) {
      //   setError("Ya existe el elemento en la lista");
      //   return;
      // }

      // setProductDetails([...productDetails, data]);
    } catch (error) {
      // setError(error.message);
      // dispatch(setApiError(error.message))
    } finally {
      setProductLoading(false);
    }
  };

  const postRestoTable = async () => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      // stockSpreadSheet.control = control;
      // stockSpreadSheet.number = selectedStockPlace.id;
      // stockSpreadSheet.stockProducts = productDetails;

      var request = {
        restoTable: restoTable,
      };

      const response = await apiCall.post(`/resto/restotable`, request);

      if (response.data === true) {
        // await clearData();
        //setsuccess_dlg(true);
        history.replace(`/resto`);
      }

      // console.log(data);
    } catch (error) {
      setError(error.message);
      console.error(error.message);
      // dispatch(setApiError(error.message))
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () =>
    productsModal({
      products: products,
    })
      .then(async (value) => {
        await fetchProduct(value);
      })
      .catch((e) => {});

  const handleEdit = async (idk) => {
    editRestoTableModal({
      product: restoTable?.items[idk],
      productName: ProductName(restoTable?.items[idk].productId),
    })
      .then((res) => {
        const { quantity, obs } = res;

        debugger;

        let tempItems = [...restoTable.items];
        let item = tempItems[idk];
        item.quantity = quantity;
        item.observaciones = obs;

        setRestoTable({ ...restoTable, items: tempItems });
        //await fetchProduct(value);
      })
      .catch((e) => {});
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Resto</title>
          </MetaTags>
          <BreadCrumb
            title={`Mesa ${restoTable?.number ?? ""}`}
            pageTitle="Resto"
            link="/resto"
          />

          <Row>
            {loading ? (
              <Spinner />
            ) : (
              <Col className="p-0" xl={12}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <button
                          onClick={async (e) => {
                            await postRestoTable();
                          }}
                          className="btn btn-primary btn-sm me-2"
                        >
                          <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                          Guardar
                        </button>
                        <button
                          onClick={async (e) => {
                            history.replace(`/resto`);
                          }}
                          className="btn btn-secondary btn-sm"
                        >
                          <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                          Descartar
                        </button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="input-group mb-2">
                      <button
                        onClick={handleClick}
                        className="btn btn-icon btn-secondary"
                        disabled={productLoading}
                      >
                        {productLoading ? (
                          <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
                        ) : (
                          <i className="ri-search-2-line" />
                        )}
                      </button>

                      <input
                        type="text"
                        className="form-control"
                        onKeyUp={onKeyUp}
                        value={productValue}
                        onChange={onChangeProductValue}
                        ref={inputProductRef}
                        autoFocus={true}
                        disabled={productLoading}
                        placeholder="Ingrese un producto"
                      />
                      <div className="ms-1 me-1 input-step">
                        <button
                          type="button"
                          className="minus"
                          onClick={() => {
                            decrement();
                          }}
                        >
                          –
                        </button>
                        <Input
                          type="number"
                          onKeyUp={onKeyUp}
                          className="product-quantity"
                          onChange={(e) => {
                            setQuantity(e.target.value);
                          }}
                          value={quantity}
                          min="0"
                          max="100"
                        />
                        <button
                          type="button"
                          className="plus"
                          onClick={() => {
                            increment();
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    {(restoTable?.items || []).length > 0 && (
                      <div className="table-responsive">
                        <table className="table table-borderless align-middle mb-0">
                          <thead className="table-light text-muted">
                            <tr>
                              <th scope="col">Productos</th>
                              <th
                                style={{ minWidth: "90px" }}
                                scope="col"
                                className="text-end"
                              ></th>
                              <th style={{ width: "40px" }} scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(restoTable?.items || []).map((product, key) => (
                              <React.Fragment key={key}>
                                <tr>
                                  <td
                                    className={
                                      product.esPlato &&
                                      "ribbon-box ribbon-fill shadow-none mb-lg-0"
                                    }
                                  >
                                    {product.esPlato && (
                                      <div class="ribbon ribbon-dish ribbon-primary">
                                        <i className="bx bx-dish"></i>
                                      </div>
                                    )}
                                    <h5 className="fs-14">
                                      [{product.productId}]{" "}
                                      {ProductName(product.productId)}
                                    </h5>
                                    <p className="text-muted mb-0">
                                      $ {product.price.toFixed(2)} x{" "}
                                      {product.quantity}
                                    </p>
                                  </td>
                                  <td className="text-end">
                                    $ {ProductTotal(product).toFixed(2)}
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li className="list-inline-item edit">
                                        <Link
                                          to="#"
                                          className="text-primary d-inline-block edit-item-btn"
                                          disabled={loading}
                                          onClick={() => {
                                            handleEdit(key);
                                          }}
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li className="list-inline-item">
                                        <Link
                                          to="#"
                                          className="text-danger d-inline-block remove-item-btn"
                                          disabled={loading}
                                          onClick={() => {
                                            removeItem(key);
                                          }}
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </CardBody>
                  <div class="card-footer">
                    <div class="row justify-content-end gy-3">
                      <div className="d-flex justify-content-end gap-2 text-muted">
                        <h5 className="fs-14 fw-semibold mb-0">
                          $
                          <span className="product-line-price">
                            {" "}
                            {total.toFixed(2)}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <ModalContainer />
      {success_dlg && (
        <SweetAlert
          success
          title="¡Bien!"
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          Mesa guardada
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default withRouter(RestoTable);

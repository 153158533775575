import React from "react";
import parckageInfo from "../../../package.json";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";

import avatar10 from "../../assets/images/brands/ypf-large.png";
import fuelStation from "../../assets/images/fuel_station.png";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

import logoLight from "../../assets/images/logo.png";

const Login = (props) => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "sa@grupooleum.com.ar" || "",
      password: "5749" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresa un email válido"),
      password: Yup.string().required("Ingresa una contraseña válida"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history, queryParams.get("to")));
    },
  });

  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>

          <div className="shape">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1440 120"
            >
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
          </div>
        </div>
        <div className="auth-page-content">
          <MetaTags>
            <title>Grupo Oleum - Iniciar sesión</title>
          </MetaTags>
          <Container>
            {/* <Row>
            <Col lg={12}>
              <div
                className="text-center mt-sm-5 mb-2 text-white-50 mt-2"
                data-aos="fade-down"
              >
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="70" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row> */}


            <Row className="mt-5 justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-5" data-aos="fade-up">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <div className="position-relative d-inline-block">
                        <img
                          src={avatar10}
                          alt=""
                          className="avatar-lg rounded-circle img-thumbnail"
                        />
                        <span className="contact-active position-absolute rounded-circle bg-success">
                          <span className="visually-hidden"></span>
                        </span>
                      </div>
                    </div>

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        {error && error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        <div className="mb-3">
                          <Label htmlFor="station" className="form-label">
                            Estación
                          </Label>
                          <Input
                            name="station"
                            className="form-control"
                            placeholder="EE SS Central"
                            type="text"
                            disabled={true}
                            value="EESS Central"
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            disabled={loading}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              ¿Olvidó su contraseña?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              className="form-control pe-5"
                              placeholder="Password"
                              disabled={loading}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                            disabled={loading}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Recordarme
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="primary"
                            className="btn btn-primary w-100"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
                            ) : null}
                            Iniciar sesión
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">Versión {parckageInfo.version}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import * as signalR from "@microsoft/signalr";
import { version } from "../../../package.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import classnames from "classnames";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  useEffect(() => {
    if (localStorage.token == null) return;

    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notifications?appVersion=" + version, {
        accessTokenFactory: () => localStorage.token,
      })
      .withAutomaticReconnect()
      .build();

    // if (!("Notification" in window)) {
    //   console.log("This browser does not support desktop notification")
    // } else {
    //   Notification.requestPermission()
    // }

    hubConnection.start();

    // hubConnection.on("notifications", n => {
    //   setRefresh(true)

    //   var options = {
    //     body: n.body,
    //     icon: logoLightPng,
    //     dir: "ltr",
    //   }
    //   var notification = new Notification(n.header, options)
    //   notification.onclick(e => {
    //     e.preventDefault()
    //     window.open("http://www.mozilla.org", "_blank")
    //   })
    //   console.log("message", n.header)
    // })

    hubConnection.on("update", (u) => {
      if (!toast.isActive("my-notification")) {
        toast("🟢 ¡Nueva versión de la app!", {
          position: "top-right",
          autoClose: false,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: false,
          onClick: onToastClick,
          toastId: "my-notification",
        });
      }
    });
  }, []);

  const onToastClick = (e) => {
    refreshCacheAndReload();
  };

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
        >
          <i className="bx bx-bell fs-22"></i>
          {/* <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            3<span className="visually-hidden">unread messages</span>
          </span> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notificaciones{" "}
                  </h6>
                </Col>
                {/* <div className="col-auto dropdown-tabs">
                  <span className="badge badge-soft-light fs-13"> 4 nuevos</span>
                </div> */}
              </Row>
            </div>
          </div>

          {/* <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
            <div className="text-reset notification-item d-block dropdown-item position-relative">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-soft-info text-info rounded-circle fs-16 shadow">
                    <i className="bx bx-badge-check"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <Link to="#" className="stretched-link">
                    <h6 className="mt-0 mb-2 lh-base">
                      De la <b>flota</b> 1{" "}
                      <span className="text-secondary">test</span> 1!
                    </h6>
                  </Link>
                  <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>
                      <i className="mdi mdi-clock-outline"></i> hace 30 seg
                    </span>
                  </p>
                </div>
                <div className="px-2 fs-15">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>

            <div className="text-reset notification-item d-block dropdown-item position-relative">
              <div className="d-flex">
                <img
                  src={avatar2}
                  className="me-3 rounded-circle avatar-xs shadow"
                  alt="user-pic"
                />
                <div className="flex-1">
                  <Link to="#" className="stretched-link">
                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                      De La Cla
                    </h6>
                  </Link>
                  <div className="fs-13 text-muted">
                    <p className="mb-1">
                      Test 1
                      🔔.
                    </p>
                  </div>
                  <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>
                      <i className="mdi mdi-clock-outline"></i> hace 48 min
                    </span>
                  </p>
                </div>
                <div className="px-2 fs-15">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>

            <div className="text-reset notification-item d-block dropdown-item position-relative">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-16 shadow">
                    <i className="bx bx-message-square-dots"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <Link to="#" className="stretched-link">
                    <h6 className="mt-0 mb-2 fs-13 lh-base">
                      Test <b className="text-success">20</b> new
                      messages in the conversation
                    </h6>
                  </Link>
                  <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>
                      <i className="mdi mdi-clock-outline"></i> hace 2 hs
                    </span>
                  </p>
                </div>
                <div className="px-2 fs-15">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>

            <div className="text-reset notification-item d-block dropdown-item position-relative">
              <div className="d-flex">
                <img
                  src={avatar8}
                  className="me-3 rounded-circle avatar-xs shadow"
                  alt="user-pic"
                />
                <div className="flex-1">
                  <Link to="#" className="stretched-link">
                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                      Maureen Gibson
                    </h6>
                  </Link>
                  <div className="fs-13 text-muted">
                    <p className="mb-1">
                      Test 3
                    </p>
                  </div>
                  <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                    <span>
                      <i className="mdi mdi-clock-outline"></i> hace 4 hs
                    </span>
                  </p>
                </div>
                <div className="px-2 fs-15">
                  <input className="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>

            <div className="my-3 text-center">
              <button
                type="button"
                className="btn btn-soft-success waves-effect waves-light shadow-none"
              >
                Ver todas{" "}
                <i className="ri-arrow-right-line align-middle"></i>
              </button>
            </div>
          </SimpleBar> */}
          <div className="w-25 w-sm-50 pt-3 mx-auto">
            <img src={bell} className="img-fluid" alt="user-pic" />
          </div>
          <div className="text-center pb-5 mt-2">
            <h6 className="fs-18 fw-semibold lh-base">
              No hay notificaciones{" "}
            </h6>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;

import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Input, Label } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { useDispatch } from "react-redux";
import { Spinner } from "../../Components/Common/Spinner";

const Dashboard = () => {
  const [shifts, setShifts] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const [date, setDate] = useState(defaultDate);

  const onSetDate = (date) => {
    setDate(new Date(date));
  };

  const fetchData = useCallback(async () => {
    try {
      // dispatch(setApiError(''))

      setLoading(true);

      const { data } = await apiCall.get(
        `/shifts/closedshifts/${moment(date).format("YYYY-MM-DD")}`
      );

      setShifts(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setApiError(error.message));
    }
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Turnos</title>
          </MetaTags>
          <BreadCrumb title="Turnos" link="/" pageTitle="Inicio" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardBody className="border-bottom-dashed border-bottom">
                  <form>
                    <Row className="g-3">
                      <Col xl={12}>
                        <Row className="g-3">
                          <Col sm={4}>
                            <Flatpickr
                              className="form-control"
                              value={date}
                              onChange={onSetDate}
                              options={{
                                allowInput: true,
                                altInput: true,
                                altFormat: "d/m/y",
                                dateFormat: "y-m-d",
                                mode: "single",
                                locale: Spanish,
                              }}
                            />
                          </Col>

                          <Col sm={4}>
                            <button
                              onClick={async (e) => {
                                e.preventDefault();
                                await fetchData();
                              }}
                              className="btn btn-primary w-100"
                            >
                              {" "}
                              <i className="ri-equalizer-fill me-2 align-middle"></i>
                              Filtrar
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            {loading ? (
              <Spinner />
            ) : (
              (shifts || []).map((item, key) => (
                <Col xxl={3} md={6} key={key}>
                  <Card className="mb-2" key={key} data-aos="zoom-in">
                    <CardBody>
                      <div className="d-flex mb-3">
                        <div className="flex-shrink-0 avatar-sm">
                          <div className="avatar-title bg-light rounded shadow">
                            <i
                              className={`bx bx bx-user ${
                                item.closed ? "text-danger" : "text-success"
                              } fs-20`}
                            ></i>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h5 className="fs-15 mb-1">
                            TURNO {item.shiftNumber}
                          </h5>
                          <p className="text-muted mb-2">
                            {item.departmentName}
                          </p>
                        </div>
                        <div>
                          {item.closed ? (
                            <span className="badge badge-soft-danger">
                              Cerrado
                            </span>
                          ) : (
                            <span className="badge badge-soft-success">
                              En curso
                            </span>
                          )}
                        </div>
                      </div>

                      <h6 className="text-muted mb-0">
                        Contado: $ {item.cashSales}
                      </h6>
                      <h6 className="text-muted mb-0">
                        Cuenta corriente: $ {item.accountingSales}
                      </h6>
                      <h5 className="text-muted mb-0">
                        <span className="badge badge-soft-primary">
                          $ {item.totalSales}
                        </span>
                      </h5>
                    </CardBody>
                    <CardBody className="border-top border-top-dashed">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className={"flex-shrink-0 text-muted mb-0"}>
                            <i className="ri-time-line align-bottom"></i>{" "}
                            {item.openTime}
                          </h6>
                        </div>
                        {item.closeTime && (
                          <h6 className={"flex-shrink-0 text-danger mb-0"}>
                            <i className="ri-time-line align-bottom"></i>{" "}
                            {item.closeTime}
                          </h6>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

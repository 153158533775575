import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import TableContainer from "../../Components/Common/TableContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setApiError } from "../../store/actions";
import { Spinner } from "../../Components/Common/Spinner";
import NumberFormat from "react-number-format";

const AccountingSuppliers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: "Nombre",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/accounting/supplierpayments/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Saldo",
        accessor: "amount",
        filterable: false,
        Cell: (cell) => {
          return (
            <span
              className={`fw-bold ${
                cell.row.original.amount < 0 ? "text-danger" : "text-success"
              }`}
            >
              {" "}
              <NumberFormat
                value={cell.value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$ "}
              />
            </span>
          );
        },
      },
      {
        Header: "Ultimo pago",
        accessor: "lastPayment",
        filterable: false,
        Cell: (cell) => {
          return <span> {moment(cell.value).format("DD/MM/YY")}</span>;
        },
      },
    ],
    []
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        // dispatch(setApiError(''))
  
        setLoading(true);
  
        const { data } = await apiCall.get(`/accounting/suppliers`);
  
        setData(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
  
        dispatch(setApiError(error.message));
      }
    };

    fetchData();
  }, []);

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Cuentas corrientes de proveedores</title>
          </MetaTags>
          <BreadCrumb title="Cuentas corrientes" link="/" pageTitle="Inicio" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0 flex-grow-1">Proveedores</h4>
                </CardHeader>
                {loading ? (
                  <Spinner />
                ) : (
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={data}
                      customPageSize={10}
                      isGlobalFilter={true}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountingSuppliers;

import React, { useEffect, useState } from "react";
import { Card, CardBody,CardHeader, Col, Container, Row, Input, Label } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/small/img-1.jpg";

const News = () => {
  const newsFeed = [
    {
      id: 1,
      img: img1,
      caption:
        "¡Nueva app!",
      body: "Presentamos una nueva app para que puedas manejar y controlar la estación desde la cualquier parte.",
      date: "Abr 25, 2022",
      time: "09:22 AM",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Novedades</title>
          </MetaTags>
          <BreadCrumb title="Novedades" link="/" pageTitle="Inicio" />
          <Col xxl={4}>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Ultimas novedades</h4>
              </CardHeader>

              <CardBody>
                {(newsFeed || []).map((item, key) => (
                  <div
                    className={
                      item.id === 1 ? "d-flex align-middle" : "d-flex mt-4"
                    }
                    key={key}
                  >
                    <div className="flex-shrink-0">
                      <img
                        src={item.img}
                        className="rounded img-fluid shadow"
                        style={{ height: "60px" }}
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-1 lh-base">
                        <Link to="#" className="text-reset">
                          {item.caption}
                        </Link>
                      </h6>
                      <p className="text-muted fs-12 mb-0">
                        {item.body}
                      </p>
                      <p className="text-muted fs-12 mb-0">
                        {item.date}{" "}
                        <i className="mdi mdi-circle-medium align-middle mx-1"></i>
                        {item.time}
                      </p>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default News;

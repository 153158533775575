import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import TableContainer from "../../Components/Common/TableContainer";
import { useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setApiError } from "../../store/actions";
import { Spinner } from "../../Components/Common/Spinner";

const SupplierPayments = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const [date, setDate] = useState(defaultDate);

  const onSetDate = (date) => {
    setDate(new Date(date));
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        // dispatch(setApiError(''))
        setLoading(true);
        const { data } = await apiCall.get(
          `/accounting/supplierpayments?id=${id}&toDate=${moment(date).format(
            "YYYY-MM-DD"
          )}`
        );
  
        setData(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setApiError(error.message));
      }
    };

    fetchData();
  }, []);

  

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "code",
        filterable: false,
      },
      {
        Header: "Nombre",
        accessor: "supplierName",
        filterable: false,
      },
      {
        Header: "Comprobante",
        accessor: "invoice",
        filterable: false,
      },
      {
        Header: "Saldo",
        accessor: "balance",
        filterable: false,
        Cell: (cell) => {
          return <span> $ {cell.value}</span>;
        },
      },
      {
        Header: "Vencimiento",
        accessor: "dueDate",
        filterable: false,
        Cell: (cell) => {
          return <span> {moment(cell.value).format("DD/MM/YY")}</span>;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Cuentas corrientes de proveedores</title>
          </MetaTags>
          <BreadCrumb title="Cuentas corrientes" link="/" pageTitle="Inicio" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0 flex-grow-1">
                    Pagos a realizar
                  </h4>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={6} className="col-xxl-2">
                        <div>
                          <Flatpickr
                            className="form-control"
                            value={date}
                            onChange={onSetDate}
                            options={{
                              allowInput: true,
                              altInput: true,
                              altFormat: "d/m/y",
                              dateFormat: "y-m-d",
                              mode: "single",
                              locale: Spanish,
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-1">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={async (e) => {
                              e.preventDefault();
                              await fetchData();
                            }}
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filtrar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={data}
                      customPageSize={10}
                      isGlobalFilter={true}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SupplierPayments;

import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import apiCall from "../../api";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { useDispatch } from "react-redux";
import { Spinner } from "../../Components/Common/Spinner";
import TableContainer from "../../Components/Common/TableContainer";

const InCharge = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const [fromDate, setFromDate] = useState(defaultDate);
  const [toDate, setToDate] = useState(defaultDate);

  const onSetFromDate = (date) => {
    setFromDate(new Date(date));
  };

  const onSetToDate = (date) => {
    onSetToDate(new Date(date));
  };

  useEffect(() => {
    const fetchInChargeLogs = async () => {
      try {
        // dispatch(setApiError(''))
  
        setLoading(true);
  
        const { data } = await apiCall.get(
          `/shifts/inchargelogs?fromDate=${moment(fromDate).format(
            "YYYY-MM-DD"
          )}&toDate=${moment(toDate).format("YYYY-MM-DD")}`
        );
  
        setData(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setApiError(error.message));
      }
    };

    fetchInChargeLogs();
  }, []);

  

  const columns = useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Turno",
        accessor: "shiftNumber",
        filterable: false,
        disableGlobalFilter: true,
      },
      {
        Header: "Proceso",
        accessor: "processName",
        filterable: false,
      },
      {
        Header: "Comprobante",
        accessor: "invoice",
        filterable: false,
      },
      {
        Header: "Fecha",
        accessor: "logDate",
        filterable: false,
        Cell: (cell) => {
          return <span> {moment(cell.value).format("DD/MM/YY hh:mm:ss")}</span>;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Grupo Oleum - Logs de encargado</title>
          </MetaTags>
          <BreadCrumb title="Logs de encargado" link="/" pageTitle="Inicio" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardBody className="border-bottom-dashed border-bottom">
                  <form>
                    <Row className="g-3">
                      <Col xl={12}>
                        <Row className="g-3">
                          <Col sm={4}>
                            <Flatpickr
                              className="form-control"
                              value={fromDate}
                              onChange={onSetFromDate}
                              options={{
                                allowInput: true,
                                altInput: true,
                                altFormat: "d/m/y",
                                dateFormat: "y-m-d",
                                mode: "single",
                                locale: Spanish,
                              }}
                            />
                          </Col>
                          <Col sm={4}>
                            <Flatpickr
                              className="form-control"
                              value={toDate}
                              onChange={onSetToDate}
                              options={{
                                allowInput: true,
                                altInput: true,
                                altFormat: "d/m/y",
                                dateFormat: "y-m-d",
                                mode: "single",
                                locale: Spanish,
                              }}
                            />
                          </Col>

                          <Col sm={4}>
                            <button
                              onClick={async (e) => {
                                e.preventDefault();
                                await fetchInChargeLogs();
                              }}
                              className="btn btn-primary w-100"
                            >
                              {" "}
                              <i className="ri-equalizer-fill me-2 align-middle"></i>
                              Filtrar
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            {loading ? (
              <Spinner />
            ) : (
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={data}
                    customPageSize={10}
                    isGlobalFilter={true}
                    divClass="table-responsive table-card mb-1"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  />
                </CardBody>
              </Card>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InCharge;
